import React from "react";

import {
  Paper,
} from "@material-ui/core";

import PublicPage from "../public.js";
import {
  SignIn,
} from "../../components/auth/signin.js";

export default function SignInPage () {
  // TODO: add loading / progress overlay during sign-in
  return (
    <PublicPage>
      <Paper>
        <SignIn />
      </Paper>
    </PublicPage>
  );
}
