import {
  useLocation,
} from "react-router-dom";

import {
  paths,
} from "./paths.js";

import {
  useAuth,
  useAuthProvider,
} from "../components/auth/core.js";
export { useAuth, useAuthProvider };

// the page to redirect to after successful signin
export const defaultSignInDestination = {
  pathname: paths.console.dashboard,
};

export function useSignInDestination () {
  const location = useLocation();

  return (location.state?.referrer || defaultSignInDestination);
}
