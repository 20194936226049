import {
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";

const appDrawerWidth = 240;

// primary: "#26367d", // Primary Colour: Air Force Blue
// secondary_1: "#2d286b", // Secondary Colour: Dull Navy
// secondary_2: "#179594", // Secondary Colour: Dark Cyan
// secondary_3: "#40b496", // Secondary Colour: Shade of Green
// sidebar: "#40b496", // Secondary Colour: Shade of Green

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#26367d", // Primary Colour: Air Force Blue
    },
    secondary: {
      main: "#179594", // Secondary Colour: Dark Cyan
    },
  },
});

export const useStyles = makeStyles((theme) => {
  console.log("makeStyles (theme)", theme);

  return ({
    root: {
      display: "flex",
    },

    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },

    appBar: {
      // TODO: figure out why theme.zIndex.drawer isn't working when published but works on localhost (maybe a library
      // version issue?)
      zIndex: 1201, // theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: appDrawerWidth,
      // width: `calc(100% - ${appDrawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },

    // main: {
    //   minWidth: "100vi",
    //   minHeight: "100vb",
    // },

    title: {
      flexGrow: 1,
    },

    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: appDrawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,

    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },

    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },

    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },

    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },

    itemmedia: {
      height: 400,
    },

    logoPaper: {
    },
    logoImage: {
      // height: "40vmin",
      objectFit: "cover",
    },
  });
});
