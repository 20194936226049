import React from "react";

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

import {
  useStyles,
} from "../lib/styles.js";

import InfoCard from "./info-card.js";

export default function ItemCard ({item}) {
  // TODO: pass styles in props?
  const classes = useStyles();
  console.log("ItemCard", {item});

  return (
    <>
      <Card>
        <CardMedia className={classes.itemmedia}
                   image={item?.product?.image?.url}
                   title={item?.product?.title}
        />

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {item?.product?.title}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            {item?.product?.description}
          </Typography>

        </CardContent>
      </Card>

      {(item?.extra_info?.size > 0)
       ? <InfoCard item={{title: "WINE SEAL", description: item.extra_info.get("one.veatech/demo")}}/>
       : null}

    </>
  );
}
