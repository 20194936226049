import {
  generatePath,
} from "react-router-dom";


// TODO: find a proper URL / pathname composition library
const paths = {
  auth: "/a/auth",
  console: {
    dashboard: "/a/console/dashboard",
    organization: "/a/console/organization",
    users: "/a/console/users",
    products: "/a/console/products",
    scans: "/a/console/scans"
  },
  error: "/a/error",
  item: "/:batchId/:compactItemId",
  itemPage: "/i/:batchId/:compactItemId",
  itemUnknown: "/i/unknown",
  root: "/",
  signIn: "/a/signin",
  signOut: "/a/signout",
};

const defaultBatchId = "0";
const nullCompactItemId = "00000000000000000000000000"; // in compact-uuids form

function generateItemPathFromQuery (query) {
  return generatePath(paths.itemPage, {
    batchId: (query?.batch ?? defaultBatchId),
    compactItemId: (query?.item ?? nullCompactItemId),
  });
}

export { paths, generateItemPathFromQuery };
