import React from "react";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";

import Amplify from "aws-amplify";
import {
  Logger,
} from "aws-amplify";

import {
  useQuery,
} from "./components/qs.js";

import {
  useItemQuery,
} from "./components/item-query.js";

import AuthPage from "./pages/auth/amplify.js";
import ConsoleDashboardPage from "./pages/console/dashboard.js";
import ConsoleOrganizationPage from "./pages/console/organization.js";
import ConsoleUsersPage from "./pages/console/users.js";
import ConsoleProductsPage from "./pages/console/products.js";
import ConsoleScansPage from "./pages/console/scans.js";
import ErrorPage from "./pages/error.js";
import ItemPage from "./pages/item.js";
import LoadingPage from "./pages/loading.js";
import LogoPage from "./pages/logo.js";
import SignInPage from "./pages/auth/signin.js";

import {
  theme,
  useStyles,
} from "./lib/styles.js";

import {
  AuthProvider,
  SignOut,
} from "./components/auth";

import {
  useAuth,
  useAuthProvider,
  useSignInDestination,
} from "./lib/auth.js";

import {
  Container,
  CssBaseline,
  Grid,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import {
  generateItemPathFromQuery,
  paths,
} from "./lib/paths.js";

import awsconfig from "./aws-exports.js";

// TODO: set these based on environment
awsconfig.oauth.redirectSignIn = "https://main.vea.codes/a/auth/";
awsconfig.oauth.redirectSignOut = "https://main.vea.codes/a/auth/";

const config = {
  aws: awsconfig,
  logLevel: "VERBOSE",
  corpHomepageURL: "https://vea-tech.com/",
};
Amplify.configure(config.aws);

// TODO: take log level from environment, config, etc.? make it a setting?
const logger = new Logger("root", config.logLevel);
logger.info("starting up");

const App = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <div className={classes.root}>
            <CssBaseline />

            <AppSwitch />

          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};
export default App;

const AppSwitch = () => {
  const auth = useAuth();
  const destination = useSignInDestination();
  const location = useLocation();
  const query = useQuery();

  return (
    <>
      <Switch>
        {/* TODO: this needs to be a sub-page route thingie for all under /a/console */}
        <Route path={paths.console.dashboard}>
          {auth.user /* TODO: check user group, etc. */
           ? (<ConsoleDashboardPage />)
           : (<Redirect to={{ pathname: paths.signIn, state: { referrer: location }}} />)}
        </Route>
        <Route path={paths.console.organization}>
          {auth.user /* TODO: check user group, etc. */
           ? (<ConsoleOrganizationPage />)
           : (<Redirect to={{ pathname: paths.signIn, state: { referrer: location }}} />)}
        </Route>
        <Route path={paths.console.users}>
          {auth.user /* TODO: check user group, etc. */
           ? (<ConsoleUsersPage />)
           : (<Redirect to={{ pathname: paths.signIn, state: { referrer: location }}} />)}
        </Route>
        <Route path={paths.console.products}>
          {auth.user /* TODO: check user group, etc. */
           ? (<ConsoleProductsPage />)
           : (<Redirect to={{ pathname: paths.signIn, state: { referrer: location }}} />)}
        </Route>
        <Route path={paths.console.scans}>
          {auth.user /* TODO: check user group, etc. */
           ? (<ConsoleScansPage />)
           : (<Redirect to={{ pathname: paths.signIn, state: { referrer: location }}} />)}
        </Route>

        <Route path={paths.error}>
          {auth.user /* TODO: check user group, etc. */
           ? (<ErrorPage />)
           : (<Redirect to={{ pathname: paths.signIn, state: { referrer: location }}} />)}
        </Route>
        <Route path={paths.signIn}>
          {auth.user
           ? (<Redirect to={destination} />)
           : (<SignInPage />)}
        </Route>
        <Route path={paths.auth}><AuthPage /></Route>
        <Route path={paths.signOut}><SignOutPage /></Route>
        <Route path={paths.itemUnknown}><UnknownItemPage /></Route>
        <Route path={paths.itemPage}><PublicItemInfoPage /></Route>
        <Redirect from={paths.item} to={paths.itemPage} />
        {/* TODO: redirect all unknown URLs to logo page (instead of rendering in place) */}
        <Route path={paths.root}>
          {query?.item
           ? (<Redirect to={ generateItemPathFromQuery(query) } />)
           : (<LogoPage />)}
        </Route>
      </Switch>
    </>
  );
};

const PublicItemInfoPage = () => {
  const params = useParams();
  const { item, error } = useItemQuery(params);

  logger.info("PublicItemInfoPage", {item, error});

  // TODO: handle errors, missing item/product info, etc.

  return (
    <>
      {error
       ? <h1>error</h1>
       : (item
          ? <ItemPage item={item} />
          : <LoadingPage />)
      }
    </>
  );
};

const SignOutPage = () => {
  // TODO: fix this so it doesn't redirect while retrieving the auth info
  // const auth = useAuthProvider();
  // console.log(auth);

  return (
    <div>
      <h1>Sign Out</h1>

      {/* {auth.user */}
      {/*  ? (<SignOut />) */}
      {/*  : (<Redirect to={paths.root} />)} */}

      {/* TODO: make it redirect to root again (broken by disabling above) */}

      <SignOut />
    </div>
  );
};

const UnknownItemPage = () => {
  return (
    <div>
      <h1>Unknown Item</h1>
    </div>
  );
};
