import {
  useEffect,
  useState,
} from "react";

import {
  API,
  Logger,
  graphqlOperation,
} from "aws-amplify";

const logger = new Logger("item-query", "INFO");

const GetItem = `query($url:String) {
      item(url:$url) {
        id
        compact
        url
        product {
          id
          title
          description
          image {
            id
            url
          }
        }
        extra_info {
          key
          value
        }
      }
    }`;

// unpack extra_info, etc., from item
function hydrateItem (item) {
  let hydrated_item = JSON.parse(JSON.stringify(item));

  // HACK: yes, this is an ugly construct. It should be one statement (ternary or coalesce or ...)
  if (hydrated_item.hasOwnProperty("extra_info")) {
    hydrated_item.extra_info = new Map(hydrated_item.extra_info.map(hydrateInfo));
  }
  else {
    hydrated_item.extra_info = new Map();
  }

  logger.info("hydrateItem", {item, hydrated_item});
  return hydrated_item;
}

// unpack an individual extra_info entry
function hydrateInfo ({key, value}) {
  logger.info("hydrateInfo", {key, value});
  return [key, value];
}

export function useItemQuery ({batchId, compactItemId}) {
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // TODO: make item URL configurable (?)
    const url = `https://0.vea.codes/${batchId}/${compactItemId}`;
    logger.info("useItemQuery [sending query]", {batchId, compactItemId, url});
    API.graphql(graphqlOperation(GetItem, {url}))
      .then((result) => {
        let hydrated_item = hydrateItem(result.data.item);
        logger.info("useItemQuery [result]", {result, hydrated_item});
        setItem(hydrated_item);
      })
      .catch((err) => {
        logger.error(err);
        setError(err);
      })
    ;
  }, [batchId, compactItemId]);

  return ({
    item,
    error,
  });
}
