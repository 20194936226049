/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://ijcsz6i3tvcehepsub5mjmcpsi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-jre2px3ydva6zp7thwxgvcs2ge",
    "aws_cognito_identity_pool_id": "us-east-2:f63918bc-2cef-4825-b5a7-bb26d0420078",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_mxKK9bv1k",
    "aws_user_pools_web_client_id": "7htkd3tvnbcps45mglo0bueog9",
    "oauth": {
        "domain": "toot7438598d-7438598d-prod.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://main.vea.codes/a/auth/",
        "redirectSignOut": "http://localhost:3000/,https://main.vea.codes/a/auth/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "toot51940-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
