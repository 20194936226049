import React from "react";

import {
  Container,
} from "@material-ui/core";

import {
  useStyles,
} from "../lib/styles.js";

// TODO: use Backdrop and Progress
export default function LoadingPage (props) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <p>loading</p>
      </div>
    </Container>
  );
}
