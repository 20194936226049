import React from "react";
import {
  useState,
} from "react";

import {
  useAuth,
} from "./core.js";

import {
  useStyles,
} from "../../lib/styles.js";

import {
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

export const SignIn = () => {
  // liberally borrowed from Material-UI SignIn.js template
  // https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/sign-in/SignIn.js

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = useAuth();
  const classes = useStyles();

  // TODO: render "waiting" status while sign-in request is pending
  return (
    <Grid container
          item
          direction="row"
          justify="center"
          alignItems="center"
    >
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>

        <form className={classes.form}
              noValidate
        >
          <TextField
            required

            id="email"
            label="Email Address"
            name="email"

            value={email}
            onChange={(e) => setEmail(e.target.value)}

            autoComplete="email"
            autoFocus

            variant="filled"
            margin="normal"
            fullWidth
          />
          <TextField
            required
            type="password"

            id="password"
            label="Password"
            name="password"

            value={password}
            onChange={(e) => setPassword(e.target.value)}

            autoComplete="current-password"

            variant="filled"
            margin="normal"
            fullWidth
          />

          {/* TODO: add "remember me" (what does that entail? isn't Amplify already doing it?) */}

          {/* TODO: fix spacing before button */}

          <Button
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
            className={classes.submit}
            onClick={ (e) => {
              e.preventDefault();
              auth.signIn(email, password);
            } }
          >
            Sign In
          </Button>
        </form>
      </div>
    </Grid>
  );
};
