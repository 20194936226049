import React from "react";

import PublicPage from "./public.js";

import ItemCard from "../components/item-card.js";

export default function ItemPage (props) {
  return (
    <PublicPage>
      {/* TODO: link to product page? */}
        <ItemCard item={props.item} />
    </PublicPage>
  );
}
