import React from "react";

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

import {
  useStyles,
} from "../lib/styles.js";

export default function InfoCard ({item}) {
  // TODO: pass styles in props?
  const classes = useStyles();

  console.log("InfoCard", {item});

  return (
    <Card>
      {(item.hasOwnProperty("image"))
       ? <CardMedia className={classes.itemmedia}
                       image={item?.image?.url}
                       title={item?.title}
             />
       : null}

      <CardContent>
        <Typography gutterBottom variant="subtitle1" component="h3">
          {item?.title}
        </Typography>

        <Typography variant="body2" color="textSecondary" component="pre">
          {item?.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
