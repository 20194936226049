import React from "react";
import {
  useEffect,
  useState,
} from "react";

import {
  Link as RouterLink,
  NavLink,
  useRouteMatch,
} from "react-router-dom";

import {
  paths,
} from "../../lib/paths.js";

import {
  DB,
} from "../../data";

import {
  API,
  Logger,
  graphqlOperation,
} from "aws-amplify";

import clsx from "clsx";

import {
  useStyles,
} from "../../lib/styles.js";
import Title from "../../components/title.js";

import {
  AppBar,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CropFreeIcon from "@material-ui/icons/CropFree";
import DashboardIcon from  "@material-ui/icons/Dashboard";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

// TODO: consolidate loggers and log config (level, etc.)
const logger = new Logger("console", "DEBUG");


export default function ConsoleUsersPage () {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <AppBar position="absolute"
              className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start"
                      color="inherit"
                      aria-label="open drawer sidebar"
                      /* onClick={toggleDrawer} */
                      className={clsx(classes.menuButton/* , open && classes.menuButtonHidden */)}>
            <MenuIcon />
          </IconButton>

          <Typography component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      className={classes.title}>
            Management Console
          </Typography>

          <IconButton color="inherit">
            <AccountBoxIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent"
              classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
              }}
              open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton /* onClick={toggleDrawer} */ >
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider/>

        <List>
          <ListItemLink to={paths.console.dashboard} primary="Dashboard" icon={<DashboardIcon />} />
          <ListItemLink to={paths.console.organization} primary="Organization" icon={<BusinessIcon />} />
          <ListItemLink to={paths.console.users} primary="Users" icon={<SupervisorAccountIcon />} />
          <ListItemLink to={paths.console.products} primary="Products and Codes" icon={<ShoppingCartIcon />} />
          <ListItemLink to={paths.console.scans} primary="Scans" icon={<CropFreeIcon />} />
        </List>

      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>

          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Users</Title>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {DB.users.map((user) => (<UserRow key={user.id} user={user} />))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>

          </Grid>

        </Container>

      </main>
    </>
  );
};

const UserRow = (props) => {
  const u = props.user;
  logger.debug("UserRow", {u});

  return (
    <TableRow key={u.id}>
      <TableCell>{u.id}</TableCell>
      <TableCell>{u.email}</TableCell>
      <TableCell>{u.name}</TableCell>
    </TableRow>
  );
};

const ListItemLink = (props) => {
  const { icon, primary, to } = props;
  const match = useRouteMatch();

  logger.debug("ListItemLink", {match});

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <NavLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} selected={ match.path === to }>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}
