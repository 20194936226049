import React from "react";

import {
  Container,
  Grid,
} from "@material-ui/core";

import {
  useStyles,
} from "../lib/styles.js";

// Wrapper for a public, non-app page (centered content)
export default function PublicPage (props) {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container
              direction="row"
              alignItems="stretch"
              justify="center"
        >
          <Grid container
                item
                alignItems="stretch"
                justify="space-evenly"
                xs={12}
          >

          {props.children}

          </Grid>
        </Grid>
      </Container>
    </>
  );
}
