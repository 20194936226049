// See https://usehooks.com/useAuth/ for inspiration

import React from "react";
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  Auth,
  Logger,
} from "aws-amplify";

// TODO: consolidate loggers and log config
const logger = new Logger("auth", "INFO");
const authContext = createContext();

// provider component - children call `useAuth()` to access auth info
export function AuthProvider ({ children }) {
  const auth = useAuthProvider();
  return (
    <authContext.Provider value={ auth }>{ children }</authContext.Provider>
  );
}

// TODO: I think this might be incorrect.
export const useAuth = () => {
  return useContext(authContext);
};

export function useAuthProvider () {
  const [user, setUser] = useState(null);

  useEffect(() => {
    currentAuthenticatedUser();
  }, []);

  const signUp = () => {
    // result <= username, password, attributes: { email, phone_number, "custom:attr": "custom attr value" }
    // { user: CognitoUser, userConfirmed: boolean, userSub: string }
    throw new Error("signUp not implemented");
  };

  const confirmSignUp = () => {
    // ? <= username, code
    throw new Error("confirmSignUp not implemented");
  };

  const signIn = (username, password) => {
    // user <= username, password
    Auth.signIn(username, password)
      .then((user) => { logger.info(user); setUser(user); })
      .catch((err) => { logger.error(err); setUser(null); })
    ;
  };

  const signOut = () => {
    Auth.signOut()
      .then(() => { logger.info("logged out"); setUser(null); })
      .catch((err) => { logger.error(err); setUser(null); })
    ;
  };

  const currentAuthenticatedUser = () => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user) => { logger.info(user); setUser(user); })
      .catch((err) => { logger.error(err); setUser(null); })
    ;
  };

  return {
    user,
    confirmSignUp,
    currentAuthenticatedUser,
    signIn,
    signOut,
    signUp,
  };
}
