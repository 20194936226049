/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateItemInquiryScanEvent = /* GraphQL */ `
  subscription OnCreateItemInquiryScanEvent {
    onCreateItemInquiryScanEvent {
      id
      timestamp
      eventType
      payloadType
      payloadData
      appSessionId
      item {
        id
        compact
        url
        product {
          id
          title
          description
          image {
            id
            url
            file {
              bucket
              region
              key
            }
          }
        }
      }
      mobileLocationReading {
        coords {
          accuracy
          altitudeAccuracy
          altitude
          heading
          latitude
          longitude
          speed
        }
        mocked
        timestamp
      }
    }
  }
`;
export const onRegisterMobileAppSession = /* GraphQL */ `
  subscription OnRegisterMobileAppSession {
    onRegisterMobileAppSession {
      id
      timestamp
      deviceId
      installationId
      releaseChannel
      releaseId
      revisionId
    }
  }
`;
export const onRegisterUserSession = /* GraphQL */ `
  subscription OnRegisterUserSession {
    onRegisterUserSession {
      id
      timestamp
      userId
      appSessionId
    }
  }
`;
export const onCreateDdbMobileAppSession = /* GraphQL */ `
  subscription OnCreateDdbMobileAppSession {
    onCreateDDBMobileAppSession {
      id
      timestamp
      deviceId
      installationId
      releaseChannel
      releaseId
      revisionId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDdbMobileAppSession = /* GraphQL */ `
  subscription OnUpdateDdbMobileAppSession {
    onUpdateDDBMobileAppSession {
      id
      timestamp
      deviceId
      installationId
      releaseChannel
      releaseId
      revisionId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDdbMobileAppSession = /* GraphQL */ `
  subscription OnDeleteDdbMobileAppSession {
    onDeleteDDBMobileAppSession {
      id
      timestamp
      deviceId
      installationId
      releaseChannel
      releaseId
      revisionId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDdbUserSession = /* GraphQL */ `
  subscription OnCreateDdbUserSession {
    onCreateDDBUserSession {
      id
      timestamp
      userId
      appSessionId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDdbUserSession = /* GraphQL */ `
  subscription OnUpdateDdbUserSession {
    onUpdateDDBUserSession {
      id
      timestamp
      userId
      appSessionId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDdbUserSession = /* GraphQL */ `
  subscription OnDeleteDdbUserSession {
    onDeleteDDBUserSession {
      id
      timestamp
      userId
      appSessionId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDdbItemInquiryScanEvent = /* GraphQL */ `
  subscription OnCreateDdbItemInquiryScanEvent {
    onCreateDDBItemInquiryScanEvent {
      id
      timestamp
      eventType
      payloadType
      payloadData
      appSessionId
      itemId
      mlr_mocked
      mlr_timestamp
      mlr_coords_accuracy
      mlr_coords_altitudeAccuracy
      mlr_coords_altitude
      mlr_coords_heading
      mlr_coords_latitude
      mlr_coords_longitude
      mlr_coords_speed
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDdbItemInquiryScanEvent = /* GraphQL */ `
  subscription OnUpdateDdbItemInquiryScanEvent {
    onUpdateDDBItemInquiryScanEvent {
      id
      timestamp
      eventType
      payloadType
      payloadData
      appSessionId
      itemId
      mlr_mocked
      mlr_timestamp
      mlr_coords_accuracy
      mlr_coords_altitudeAccuracy
      mlr_coords_altitude
      mlr_coords_heading
      mlr_coords_latitude
      mlr_coords_longitude
      mlr_coords_speed
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDdbItemInquiryScanEvent = /* GraphQL */ `
  subscription OnDeleteDdbItemInquiryScanEvent {
    onDeleteDDBItemInquiryScanEvent {
      id
      timestamp
      eventType
      payloadType
      payloadData
      appSessionId
      itemId
      mlr_mocked
      mlr_timestamp
      mlr_coords_accuracy
      mlr_coords_altitudeAccuracy
      mlr_coords_altitude
      mlr_coords_heading
      mlr_coords_latitude
      mlr_coords_longitude
      mlr_coords_speed
      createdAt
      updatedAt
    }
  }
`;
