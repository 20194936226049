/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const companies = /* GraphQL */ `
  query Companies {
    companies {
      id
      name
      slug
      homepage
      products {
        id
        title
        description
        image {
          id
          url
          file {
            bucket
            region
            key
          }
        }
      }
    }
  }
`;
export const company = /* GraphQL */ `
  query Company($id: ID!) {
    company(id: $id) {
      id
      name
      slug
      homepage
      products {
        id
        title
        description
        image {
          id
          url
          file {
            bucket
            region
            key
          }
        }
      }
    }
  }
`;
export const products = /* GraphQL */ `
  query Products {
    products {
      id
      title
      description
      image {
        id
        url
        file {
          bucket
          region
          key
        }
      }
    }
  }
`;
export const product = /* GraphQL */ `
  query Product($id: ID!) {
    product(id: $id) {
      id
      title
      description
      image {
        id
        url
        file {
          bucket
          region
          key
        }
      }
    }
  }
`;
export const items = /* GraphQL */ `
  query Items {
    items {
      id
      compact
      url
      product {
        id
        title
        description
        image {
          id
          url
          file {
            bucket
            region
            key
          }
        }
      }
    }
  }
`;
export const item = /* GraphQL */ `
  query Item($id: ID, $compact: ID, $url: String) {
    item(id: $id, compact: $compact, url: $url) {
      id
      compact
      url
      product {
        id
        title
        description
        image {
          id
          url
          file {
            bucket
            region
            key
          }
        }
      }
    }
  }
`;
export const debug = /* GraphQL */ `
  query Debug($secret: String!) {
    debug(secret: $secret) {
      env
    }
  }
`;
export const getDdbMobileAppSession = /* GraphQL */ `
  query GetDdbMobileAppSession($id: ID!) {
    getDDBMobileAppSession(id: $id) {
      id
      timestamp
      deviceId
      installationId
      releaseChannel
      releaseId
      revisionId
      createdAt
      updatedAt
    }
  }
`;
export const listDdbMobileAppSessions = /* GraphQL */ `
  query ListDdbMobileAppSessions(
    $filter: ModelDDBMobileAppSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDDBMobileAppSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        deviceId
        installationId
        releaseChannel
        releaseId
        revisionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDdbUserSession = /* GraphQL */ `
  query GetDdbUserSession($id: ID!) {
    getDDBUserSession(id: $id) {
      id
      timestamp
      userId
      appSessionId
      createdAt
      updatedAt
    }
  }
`;
export const listDdbUserSessions = /* GraphQL */ `
  query ListDdbUserSessions(
    $filter: ModelDDBUserSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDDBUserSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        userId
        appSessionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDdbItemInquiryScanEvent = /* GraphQL */ `
  query GetDdbItemInquiryScanEvent($id: ID!) {
    getDDBItemInquiryScanEvent(id: $id) {
      id
      timestamp
      eventType
      payloadType
      payloadData
      appSessionId
      itemId
      mlr_mocked
      mlr_timestamp
      mlr_coords_accuracy
      mlr_coords_altitudeAccuracy
      mlr_coords_altitude
      mlr_coords_heading
      mlr_coords_latitude
      mlr_coords_longitude
      mlr_coords_speed
      createdAt
      updatedAt
    }
  }
`;
export const listDdbItemInquiryScanEvents = /* GraphQL */ `
  query ListDdbItemInquiryScanEvents(
    $filter: ModelDDBItemInquiryScanEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDDBItemInquiryScanEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        eventType
        payloadType
        payloadData
        appSessionId
        itemId
        mlr_mocked
        mlr_timestamp
        mlr_coords_accuracy
        mlr_coords_altitudeAccuracy
        mlr_coords_altitude
        mlr_coords_heading
        mlr_coords_latitude
        mlr_coords_longitude
        mlr_coords_speed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
