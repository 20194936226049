import React from "react";

import {
  AmplifyAuthenticator,
  AmplifySignOut,
} from "@aws-amplify/ui-react";

import PublicPage from "../public.js";

import {
  paths,
} from "../../lib/paths.js";

import {
  Link,
} from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";


export default function AmplifyAuthPage () {
  return (
    <PublicPage>
      <AmplifyAuthenticator>
        <Paper>
          <Link to={paths.console.dashboard}>Console</Link>
          <AmplifySignOut/>
        </Paper>
      </AmplifyAuthenticator>
    </PublicPage>
  );
}
