import React from "react";

import {
  Link as RouterLink,
} from "react-router-dom";

import {
  useStyles,
} from "../lib/styles.js";

import {
  useAuth,
  useSignInDestination,
} from "../lib/auth.js";

import {
  Grid,
  Link,
  Paper,
} from "@material-ui/core";

import {
  paths,
} from "../lib/paths.js";

import PublicPage from "./public.js";

import logo from "../logo.svg";

export default function LogoPage () {
  const auth = useAuth();
  const classes = useStyles();
  const destination = useSignInDestination();

  console.log(auth);

  return (
    <PublicPage>
      <Grid container
            item
            direction="column"
            alignItems="stretch"
            justify="center"
      >
        <Paper elevation={0}
               className={classes.logoPaper}
        >

          {/* TODO: make URL, alt, etc., configurable */}
          <a href="https://vea-tech.com/">
            <img src={logo}
                 className={classes.logoImage}
                 alt="VEA Technologies logo"
            />
          </a>
        </Paper>

        {/* TODO: figure out how to position the stupid link centered below the logo */}

        {/* <Paper elevation={0}> */}
        {/*   {auth.user */}
        {/*    ? (<Link component={RouterLink} to={destination}>Go to Console</Link>) */}
        {/*    : (<Link component={RouterLink} to={paths.signIn}>Sign In</Link>)} */}
        {/* </Paper> */}

      </Grid>
    </PublicPage>
  );
};
