import React from "react";
import {
  useLocation,
} from "react-router-dom";

import qs from "query-string";

export function useQuery () {
  return qs.parse(useLocation().search);
}
