export const DB = {
  organizations: [
    {
      "slug": "cokeza",
      "name": "Coca-Cola SA",
      "domainName": "coca-cola.co.za",
      "homepage": "https://www.coca-cola.co.za",
      "id": "28bf343c-3173-4fc5-aeb3-862e3994829d"
    },
    {
      "slug": "nativesouthafrica",
      "name": "Native South Africa",
      "domainName": "nativesouthafrica.com",
      "homepage": "https://www.nativesouthafrica.com/",
      "id": "10e20313-01f7-411b-a66c-07b0184bc3e9"
    },
    {
      "slug": "cslwines",
      "name": "Charles Lang & Sons",
      "domainName": "cslwines.com",
      "homepage": "https://www.cslwines.com/",
      "id": "857e2389-6230-47bd-85e0-8e8c8fa84ca1",
    },
  ],

  entitlements: [
    {
      "id": "bfbb19f1-e194-40a9-8925-bc2e2eb1836e",
      "name": "Authorised Distributor",
      "description": "",
    },
    {
      "id": "83ce9a38-67aa-4874-9793-12e2fc4d18b3",
      "name": "Manufacturing",
      "description": "",
    },
    {
      "id": "3f04d3b1-e694-4069-a180-9e7d28dd0eb9",
      "name": "Exclusive Retail",
      "description": "",
    },
    {
      "id": "e7391065-501b-483a-9811-f9c9b5f131cb",
      "name": "Product Owner",
      "description": "",
    },
    {
      "id": "5bc03133-294e-4a68-a227-72bf44d04e13",
      "name": "Tax Agent",
      "description": "",
    },
  ],

  users: [
    {
      "id": "008ed433-a83e-45a4-8b89-bc0085857871",
      "email": "mark-anthony.rowland@veatech.one",
      "name": "Mark-Anthony Rowland",
    },
    {
      "id": "f446e7c4-f8b8-4e1e-879c-6f216bfe04b5",
      "email": "johan.vanwyk@veatech.one",
      "name": "Johan van Wyk",
    },
    {
      "id": "a9eac6df-e8e4-475a-ab09-c7c5b6c8eb83",
      "email": "graeme.vandervelde@veatech.one",
      "name": "Graeme van der Velde",
    },
    {
      "id": "064ce269-e002-4857-b5ca-37ce8819c370",
      "email": "jose.mendoza@veatech.one",
      "name": "Jose Mendoza",
    },
    {
      "id": "5a13bf7d-13d4-4251-8166-5993604f3c8e",
      "email": "tripp.lilley@veatech.one",
      "name": "Tripp Lilley",
    },
  ],

  products: [
    // Native South Africa
    {
      "idx": 5,
      "id": "068cd33e-17d2-4135-8d3a-1b487db8d002",
      "title": "Blueberry and Coconut (100g)",
      "description": "Blueberry and coconut in raw honey dark choc coated",
      "weight": "100g"
    },
    {
      "idx": 10,
      "id": "44b24fce-7be5-492b-a239-afa2caf5b465",
      "title": "Chai and Almonds (50g)",
      "description": "Chai and Almonds sugar free",
      "weight": "50g"
    },
    {
      "idx": 12,
      "id": "c0732426-2112-44f3-9c97-ee857b4cb0d4",
      "title": "Hazelnut Mocha (50g)",
      "description": "Hazel mocha in raw honey dark choc coated",
      "weight": "50g"
    },
    {
      "idx": 13,
      "id": "84864ab3-0bf1-4a00-9cb9-8674e694d22f",
      "title": "Blueberry (50g)",
      "description": "Blue Berries in raw honey dark choc coated",
      "weight": "50g"
    },
    {
      "idx": 8,
      "id": "5c6de883-cda0-4f68-8b96-9780a4ef2740",
      "title": "Cranberry (50g)",
      "description": "Cranberry in raw honey dark choc coated with ground almonds",
      "weight": "50g"
    },
    {
      "idx": 1,
      "id": "b6d714d1-4dde-4c25-a1d9-0f25ca948c1e",
      "title": "Cranberry (100g)",
      "description": "Cranberry in raw dark choc coated with ground almonds",
      "weight": "100g"
    },
    {
      "idx": 2,
      "id": "0aa79741-5220-4527-8347-143fea291be9",
      "title": "Almonds (100g)",
      "description": "Almonds in raw honey dark choc coated",
      "weight": "100g"
    },
    {
      "idx": 3,
      "id": "57edb920-f7e3-4f6d-b610-ecba46939683",
      "title": "Macadamia (100g)",
      "description": "Macadmia in raw honey dark choc coated",
      "weight": "100g"
    },
    {
      "idx": 6,
      "id": "a4e3c56a-198f-4f33-99d4-da1831dd1595",
      "title": "Cashew and Almonds (100g)",
      "description": "Cashew and almonds in caramel coconut nectar",
      "weight": "100g"
    },
    {
      "idx": 4,
      "id": "655960f7-3da7-4f06-a31b-5d5401b9f0bd",
      "title": "Chai and Almonds (100g)",
      "description": "Chai and Almonds sugar free",
      "weight": "100g"
    },
    {
      "idx": 11,
      "id": "12a84e26-140a-4a8f-99b4-5b6ed483909d",
      "title": "Cashew and Almonds (50g)",
      "description": "Cashew and almonds in caramel coconut nectar",
      "weight": "50g"
    },
    {
      "idx": 7,
      "id": "5bdaafe2-b2ec-4388-951d-ddd4a1284b40",
      "title": "Hazelnut Mocha (100g)",
      "description": "Hazelnut mocha in raw honey dark choc coated",
      "weight": "100g"
    },
    {
      "idx": 9,
      "id": "8a3512e9-7a2f-44f3-88a3-e2baf7670b3e",
      "title": "Almonds (50g)",
      "description": "Almonds in raw honey dark choc coated",
      "weight": "50g"
    },

    // Coca-Cola SA
    {
      "idx": 1,
      "id": "3d000073-9876-40ab-b598-fe6aa066bebe",
      "title": "Coca-Cola No Sugar Soft Drink 500ml",
      "description": "Coca‑Cola without sugars is a subtle combination of ingredients that restore the taste of Coca‑Cola, but without sugars and calories.",
      "weight": ""
    },

  ],

};
