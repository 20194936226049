import React from "react";
import {
  useState,
} from "react";

import {
  useAuth,
} from "./core.js";

import {
  useStyles,
} from "../../lib/styles.js";

import {
  Button,
  Container,
} from "@material-ui/core";

export const SignOut = () => {
  const auth = useAuth();
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <form className={classes.form}
              noValidate
        >

          {/* TODO: add "remember me" (what does that entail? isn't Amplify already doing it?) */}

          <Button
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
            className={classes.submit}
            onClick={ (e) => {
              e.preventDefault();
              auth.signOut();
            } }
          >
            Sign Out
          </Button>
        </form>
      </div>
    </Container>
  );
};
