import React from "react";
import {
  useEffect,
  useState,
} from "react";

import {
  Link as RouterLink,
  NavLink,
  useRouteMatch,
} from "react-router-dom";

import {
  paths,
} from "../../lib/paths.js";

import {
  API,
  Logger,
  graphqlOperation,
} from "aws-amplify";

import {
  listDdbItemInquiryScanEvents,
  listDdbMobileAppSessions,
  listDdbUserSessions,
} from "../../graphql/queries.js";

import {
  onCreateItemInquiryScanEvent,
  onRegisterMobileAppSession,
  onRegisterUserSession,
} from "../../graphql/subscriptions";

import clsx from "clsx";

import {
  useStyles,
} from "../../lib/styles.js";
import Title from "../../components/title.js";

import {
  AppBar,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CropFreeIcon from "@material-ui/icons/CropFree";
import DashboardIcon from  "@material-ui/icons/Dashboard";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

// TODO: consolidate loggers and log config (level, etc.)
const logger = new Logger("console", "DEBUG");

// id
// eventType
// payloadType
// payloadData
// appSessionId
// item {
//   id
//   compact
//   url
// }


export default function ConsolePage () {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const [mobileAppSessionRegistrations, setMobileAppSessionRegistrations] = useState([]);
  const [userSessionRegistrations, setUserSessionRegistrations] = useState([]);
  const [itemInquiryScanEvents, setItemInquiryScanEvents] = useState([]);

  const [pastMobileAppSessionRegistrations, setPastMobileAppSessionRegistrations] = useState([]);
  const [pastUserSessionRegistrations, setPastUserSessionRegistrations] = useState([]);
  const [pastItemInquiryScanEvents, setPastItemInquiryScanEvents] = useState([]);

  useEffect(() => {
    // fetch historical data and then subscribe to new data events
    listItemInquiryScanEvents();
    listMobileAppSessions();
    listUserSessions();
    // TODO: "React Hook useEffect has missing dependencies: 'listItemInquiryScanEvents', 'listMobileAppSessions', and 'listUserSessions'. Either include them or remove the dependency array  react-hooks/exhaustive-deps"
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  async function listItemInquiryScanEvents () {
    logger.debug("listItemInquiryScanEvents");

    const res = await API.graphql(graphqlOperation(listDdbItemInquiryScanEvents, {limit: 10}));
    logger.debug("listItemInquiryScanEvents (res)", res);

    setItemInquiryScanEvents(res.data.listDDBItemInquiryScanEvents.items.map(
      (scanEvent) => {
        return {
          id: scanEvent.id,
          timestamp: scanEvent.timestamp,
          eventType: scanEvent.eventType,
          mobileLocationReading: {
            coords: {
              accuracy: scanEvent.mlr_coords_accuracy,
              altitude: scanEvent.mlr_coords_altitude,
              altitudeAccuracy: scanEvent.mlr_coords_altitudeAccuracy,
              heading: scanEvent.mlr_coords_heading,
              latitude: scanEvent.mlr_coords_latitude,
              longitude: scanEvent.mlr_coords_longitude,
              speed: scanEvent.mlr_coords_speed,
            },
            mocked: scanEvent.mlr_mocked,
            timestamp: scanEvent.mlr_timestamp,
          },
          item: {
            id: scanEvent.itemId,
            product: {
              title: "-"
            }
          },
        };
      }));

    // subscribe to new data events
    subscribeCreateItemInquiryScanEvents();
  }

  async function listMobileAppSessions () {
    logger.debug("listMobileAppSessions");

    const res = await API.graphql(graphqlOperation(listDdbMobileAppSessions, {limit: 10}));
    logger.debug("listMobileAppSessions (res)", res);

    setMobileAppSessionRegistrations(res.data.listDDBMobileAppSessions.items.map(
      (appSession) => {
        return {
          id: appSession.id,
          timestamp: appSession.timestamp,
          deviceId: appSession.deviceId,
          releaseChannel: appSession.releaseChannel,
          releaseId: appSession.releaseId,
          revisionId: appSession.revisionId,
        };
      }));

    // subscribe to new data events
    subscribeRegisterMobileAppSession();
  }

  async function listUserSessions () {
    logger.debug("listUserSessions");

    const res = await API.graphql(graphqlOperation(listDdbUserSessions, {limit: 10}));
    logger.debug("listUserSessions (res)", res);

    setUserSessionRegistrations(res.data.listDDBUserSessions.items.map(
      (userSession) => {
        return {
          id: userSession.id,
          timestamp: userSession.timestamp,
          userId: userSession.userId,
          appSessionId: userSession.appSessionId,
        };
      }));

    // subscribe to new data events
    subscribeRegisterUserSession();
  }

  async function subscribeRegisterMobileAppSession () {
    await API.graphql(graphqlOperation(onRegisterMobileAppSession)).subscribe({
      next: e => {
        logger.debug("new mobile app registration event", e);
        const newSession = e.value.data.onRegisterMobileAppSession;
        setMobileAppSessionRegistrations(oldSessions => [newSession, ...oldSessions]);
      },
    });
  }

  async function subscribeRegisterUserSession () {
    await API.graphql(graphqlOperation(onRegisterUserSession)).subscribe({
      next: e => {
        logger.debug("new user session registration event", e);
        const newSession = e.value.data.onRegisterUserSession;
        setUserSessionRegistrations(oldSessions => [newSession, ...oldSessions]);
      },
    });
  }

  async function subscribeCreateItemInquiryScanEvents () {
    await API.graphql(graphqlOperation(onCreateItemInquiryScanEvent)).subscribe({
      next: e => {
        logger.debug("new item inquiry scan event", e);
        const newScanEvent = e.value.data.onCreateItemInquiryScanEvent;
        setItemInquiryScanEvents(oldScanEvents => [newScanEvent, ...oldScanEvents]);
      },
    });
  }

  return (
    <>
      <AppBar position="absolute"
              className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start"
                      color="inherit"
                      aria-label="open drawer sidebar"
                      /* onClick={toggleDrawer} */
                      className={clsx(classes.menuButton/* , open && classes.menuButtonHidden */)}>
            <MenuIcon />
          </IconButton>

          <Typography component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      className={classes.title}>
            Management Console
          </Typography>

          <IconButton color="inherit">
            <AccountBoxIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* <Drawer variant="permanent" */}
      {/*         classes={{ */}
      {/*           paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose), */}
      {/*         }} */}
      {/*         open={open} */}
      {/* > */}
      {/*   <div className={classes.toolbarIcon}> */}
      {/*     <IconButton /\* onClick={toggleDrawer} *\/ > */}
      {/*       <ChevronLeftIcon /> */}
      {/*     </IconButton> */}
      {/*   </div> */}

      {/*   <Divider/> */}

      {/*   <List> */}
      {/*     <ListItemLink to={paths.console.dashboard} primary="Dashboard" icon={<DashboardIcon />} /> */}
      {/*     {/\* <ListItemLink to={paths.console.organization} primary="Organization" icon={<BusinessIcon />} /> *\/} */}
      {/*     {/\* <ListItemLink to={paths.console.users} primary="Users" icon={<SupervisorAccountIcon />} /> *\/} */}
      {/*     {/\* <ListItemLink to={paths.console.products} primary="Products and Codes" icon={<ShoppingCartIcon />} /> *\/} */}
      {/*     {/\* <ListItemLink to={paths.console.scans} primary="Scans" icon={<CropFreeIcon />} /> *\/} */}
      {/*   </List> */}

      {/* </Drawer> */}

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>

          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Recent Mobile Sessions</Title>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Timestamp</TableCell>
                      {/* <TableCell>Type</TableCell> */}
                      <TableCell>Session ID</TableCell>
                      <TableCell>Device ID</TableCell>
                      <TableCell>Channel</TableCell>
                      <TableCell>Revision ID</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {mobileAppSessionRegistrations.map((appSession) => (<AppSessionRow key={appSession.id} appSession={appSession}/>))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Recent User Sessions</Title>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Timestamp</TableCell>
                      {/* <TableCell>Type</TableCell> */}
                      <TableCell>User Session ID</TableCell>
                      <TableCell>User ID</TableCell>
                      <TableCell>App Session ID</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {userSessionRegistrations.map((userSession) => (<UserSessionRow key={userSession.id} userSession={userSession}/>))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Recent Scans</Title>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Timestamp</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Item ID</TableCell>
                      <TableCell>Product</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {itemInquiryScanEvents.map((scanEvent) => (<ScanEventRow key={scanEvent.id} scanEvent={scanEvent}/>))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>

        </Container>

      </main>
    </>
  );
};

const LocationLink = (props) => {
  const loc = props.location;
  logger.debug("location:", props.loc);

  try {
    const maps_url = `https://www.google.com/maps/place/${loc.coords.latitude},${loc.coords.longitude}`;
    const rounded_loc = {
      latitude: Number.parseFloat(loc.coords.latitude).toFixed(5),
      longitude: Number.parseFloat(loc.coords.longitude).toFixed(5),
      altitude: Number.parseFloat(loc.coords.altitude).toFixed(1),
    };

    return (
      <>
        <a href={maps_url} target="_blank" rel="noreferrer">{rounded_loc.latitude} {rounded_loc.longitude} {rounded_loc.altitude}</a>
      </>
    );
  }
  catch (err) {
    logger.debug("unparsable location:", err);
    return (
      <>
        {"-"}
      </>
    );
  }
};

const ScanEventRow = (props) => {
  const se = props.scanEvent;
  logger.debug("scanEvent:", se);

  return (
    <TableRow key={se.id}>
      <TableCell>{se.timestamp}</TableCell>
      <TableCell>{se.eventType}</TableCell>
      <TableCell><LocationLink location={se.mobileLocationReading} /></TableCell>
      <TableCell>{se.item?.id}</TableCell>
      <TableCell>{se.item?.product?.title}</TableCell>
    </TableRow>
  );
};

const AppSessionRow = (props) => {
  const as = props.appSession;
  logger.debug("appSession:", as);

  return (
    <TableRow key={as.id}>
      <TableCell>{as.timestamp}</TableCell>
      {/* <TableCell>{as.sessionType}</TableCell> */}
      <TableCell>{as.id}</TableCell>
      <TableCell>{as.deviceId}</TableCell>
      <TableCell>{as.releaseChannel}</TableCell>
      <TableCell>{as.revisionId}</TableCell>
    </TableRow>
  );
};

const UserSessionRow = (props) => {
  const us = props.userSession;
  logger.debug("userSession:", us);

  return (
    <TableRow key={us.id}>
    <TableCell>{us.timestamp}</TableCell>
    {/* <TableCell>{as.sessionType}</TableCell> */}
    <TableCell>{us.id}</TableCell>
    <TableCell>{us.userId}</TableCell>
    <TableCell>{us.appSessionId}</TableCell>
    </TableRow>
  );
};

const ListItemLink = (props) => {
  const { icon, primary, to } = props;
  const match = useRouteMatch();

  logger.debug("ListItemLink", {match});

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <NavLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} selected={ match.path === to }>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}
